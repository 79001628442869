import React from 'react';
import { navigate } from 'gatsby-link';
// import ReCAPTCHA from 'react-google-recaptcha';

import Layout from '../components/layout';
import SEO from '../components/seo';

// styled components
import Title from '../components/styles/title';
import ContactUsStyles from '../components/styles/contact-us-styles';

function encode(data) {
    return (
        Object.keys(data)
            // eslint-disable-next-line prefer-template
            .map(
                key =>
                    encodeURIComponent(key) +
                    '=' +
                    encodeURIComponent(data[key])
            )
            .join('&')
    );
}

const ContactUs = () => {
    const [state, setState] = React.useState({});

    const handleChange = e => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const handleSubmit = e => {
        e.preventDefault();
        const form = e.target;

        // eslint-disable-next-line no-undef
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...state,
            }),
        })
            .then(() => navigate(form.getAttribute('action')))
            .catch(error => {
                // eslint-disable-next-line no-console
                console.log('error: ', error);
            });
    };

    return (
        <Layout>
            <SEO title="Contact Us" />
            <Title>Contact Us</Title>

            <ContactUsStyles>
                <div className="ContactUs__container">
                    <h3>
                        Please contact us using the form below. We will respond
                        to you within 24 hours.
                    </h3>

                    <form
                        name="contact-us"
                        method="post"
                        action="/thanks"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit={handleSubmit}
                    >
                        {/* The `form-name` hidden field is required to 
            support form submissions without JavaScript */}
                        <input
                            type="hidden"
                            name="form-name"
                            value="contact-us"
                        />
                        <p hidden>
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label>
                                Don’t fill this out:
                                <input
                                    name="bot-field"
                                    onChange={handleChange}
                                />
                            </label>
                        </p>
                        <input
                            className="inputStyle"
                            name="name"
                            onChange={handleChange}
                            placeholder="name *"
                            required
                            type="text"
                        />
                        <input
                            className="inputStyle"
                            name="email"
                            onChange={handleChange}
                            placeholder="email *"
                            required
                            type="email"
                        />
                        <input
                            className="inputStyle"
                            name="phone number"
                            placeholder="phone number *"
                            type="tel"
                            onChange={handleChange}
                        />
                        <textarea
                            className="inputStyle Input--message"
                            name="message"
                            placeholder="message *"
                            onChange={handleChange}
                            required
                            type="text"
                        />
                        {/* <ReCAPTCHA sitekey="YOUR_SITE_KEY" /> */}
                        <button
                            className="JoinNow__button *"
                            type="submit"
                            onChange={handleChange}
                        >
                            submit
                        </button>
                    </form>
                </div>

                <div className="Map__container *">
                    <iframe
                        allowFullScreen=""
                        frameBorder="0"
                        height="550"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d46929.85908093566!2d-83.251542!3d42.680081!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8824eab44c1cae6b%3A0x1a887b140eea4f57!2s2685%20Lapeer%20Rd%20%23101%2C%20Auburn%20Hills%2C%20MI%2048326!5e0!3m2!1sen!2sus!4v1574839293469!5m2!1sen!2sus"
                        style={{ border: 0 }}
                        title="IOLCF Location Map"
                        width="946"
                    />
                    <div className="Contact__container">
                        <div className="Contact__group">
                            <h1 className="Contact__header">Addie Hampton</h1>
                            <p className="Contact__subHeader">Administration</p>
                            <a
                                className="Contact__subHeader"
                                href="mailto:ahampton@iolcf.com"
                                style={{ textDecoration: 'none' }}
                            >
                                <p>ahampton@iolcf.com</p>
                            </a>
                            <a
                                className="Contact__subHeader"
                                href="tel:248-289-0302"
                                style={{ textDecoration: 'none' }}
                            >
                                <p>(248) 289-0302</p>
                            </a>
                        </div>
                        <div className="Contact__group">
                            <h1 className="Contact__header">Brenda Symons</h1>
                            <p className="Contact__subHeader">Administration</p>
                            <a
                                className="Contact__subHeader"
                                href="mailto:bsymons@iolcf.com"
                                style={{ textDecoration: 'none' }}
                            >
                                <p>bsymons@iolcf.com</p>
                            </a>
                            <a
                                className="Contact__subHeader"
                                href="tel:248-377-1900"
                                style={{ textDecoration: 'none' }}
                            >
                                <p>(248) 377-1900 Ext. 304</p>
                            </a>
                        </div>

                        <div className="Contact__group">
                            <h1 className="Contact__header">IOLCF, Inc.</h1>
                            <address className="Contact__subHeader">
                                <p>2685 Lapeer Rd.,</p>
                                <p>Suite 201 Auburn Hills, MI 48326</p>
                            </address>
                            <a
                                className="Contact__subHeader"
                                href="tel:248-377-1900"
                                style={{ textDecoration: 'none' }}
                            >
                                <p>
                                    <span style={{ fontWeight: 'bold' }}>
                                        Phone:
                                    </span>
                                    (248) 377-1900
                                </p>
                            </a>
                            <a
                                className="Contact__subHeader"
                                href="tel:248-377-1913"
                                style={{ textDecoration: 'none' }}
                            >
                                <p>
                                    <span style={{ fontWeight: 'bold' }}>
                                        Fax:
                                    </span>
                                    (248) 377-1913
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </ContactUsStyles>
        </Layout>
    );
};

export default ContactUs;
